import React from 'react'
// import {} from 'prop-types'

import ButtonStandard from '@/component/Primitive/ButtonStandard'
import Container from '@/component/Primitive/Container'
import Prose from '@/component/Primitive/Prose'
import Section from '@/component/Primitive/Section'
import Stack from '@/component/Primitive/Stack'
import TextAlign from '@/component/Primitive/TextAlign'
import Type from '@/component/Primitive/Type'

const ErrorPage = () => (
  <div>
    <Section padded minHeight>
      <Container gutter center>
        <TextAlign center>
          <Stack gap="l">
            <Type as="h2" size="xxl" bold color="blue">
              404 - Content not found
            </Type>
            <Prose>
              <p>The content you are looking for does not exist.</p>
            </Prose>
          </Stack>
        </TextAlign>
      </Container>
    </Section>
    <Section backgroundLight shadowTop>
      <Container gutter center>
        <TextAlign center>
          <ButtonStandard to="/">Start again</ButtonStandard>
        </TextAlign>
      </Container>
    </Section>
  </div>
)

// ErrorPage.propTypes = {}

export default ErrorPage
