import React from 'react'
import Head from 'next/head'

import { footerConfig } from '../src/lib/footer-config'
import ErrorPage from '../src/component/Page/Error'
import SiteWrapper from '@/component/Landmark/SiteWrapper'

export default function Error() {
  return (
    <>
      <Head>
        <title>Citygate Service Booking</title>
      </Head>

      <SiteWrapper
        background="dashboard"
        title="Book your service"
        footerProps={footerConfig}
      >
        <ErrorPage />
      </SiteWrapper>
    </>
  )
}
